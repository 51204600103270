import React from 'react';
import Form from './Form';
import data from './data.json'

const App = () => {
    return (
        <div className="container full-height">
            <Form testdata={data}/>
        </div>
    );
};

export default App;
